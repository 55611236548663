export default {
    error: state => state.error,
    jobs: state => state.jobs,
    event: state => state.event,
    // eventId: state => state.event && state.event.id ? state.event.id : null,
    eventId: state => {
        const eventId = localStorage.getItem("te-event-id");
        return eventId ? eventId : state.event && state.event.id ? state.event.id : null
    },
    access: state => state.access,
    previousAccess: state => state.previousAccess,
    hasAccessResult: state => state.access !== null,
    isGrantedAccess: state => state.access && state.access.status && state.access.status === 'granted',
    isRequestingAccess: state => state.access && state.access.status && state.access.status === 'requested',
    accessResultMessage: state => state.access && state.access.message ? state.access.message : null,
};
