import * as types from "./mutation-types";

export default {
    [types.ERROR](state, error) {
        state.error = { message: error };
    },

    [types.FLUSH_ERROR](state) {
        state.error = { message: null };
    },

    [types.START_JOB](state, key) {
        if(state.jobs.indexOf(key) === -1) {
            state.jobs.push(key);
        }
    },

    [types.END_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },

    [types.SET_EVENT_ID](state, eventId) {
        // state.event = {id: eventId};
        localStorage.setItem("te-event-id", eventId);
    },

    [types.RECEIVED_EVENT](state, event) {
        state.event = event;
    },

    [types.CLEAR_EVENT](state) {
        state.event = null;
    },

    [types.ACCESS_REQUESTED](state) {
        state.access = {
            status: 'requested',
            message: null
        };
    },

    [types.ACCESS_GRANTED](state, message) {
        state.access = {
            status: 'granted',
            message: message
        };
    },

    [types.ACCESS_DENIED](state, message) {
        state.access = {
            status: 'denied',
            message: message
        };
    },

    [types.CLEAR_ACCESS](state) {
        state.previousAccess = state.access;
        state.access = null;
    },

};
