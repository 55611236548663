import * as mutationTypes from "./mutation-types";
import * as jobTypes from "./job-types";
import router from "@/router";
import BackendService from "@/services/backend";

const backoffice = new BackendService();



export default {

    flushError({ commit }) {
        commit(mutationTypes.FLUSH_ERROR);
    },

    async signIn({ commit, dispatch }, {username, password, eventId}) {
        dispatch('flushError');
        commit(mutationTypes.START_JOB, jobTypes.LOGIN);
        const result = await backoffice.getAuthToken({username, password});
        if (result.error) {
            commit(mutationTypes.ERROR, result.error);
            commit(mutationTypes.END_JOB, jobTypes.LOGIN);
        } else {
            commit(mutationTypes.END_JOB, jobTypes.LOGIN);
            if(eventId) {
                router.push({ name: 'event', params: { id: eventId } });
            } else {
                router.push({ name: 'event' });
            }
        }
    },

    async getEvent({ commit, dispatch }, eventId) {
        dispatch('clearEvent');
        if(eventId) {
            commit(mutationTypes.START_JOB, jobTypes.GET_EVENT);
            const query = `query { event(id: "${eventId}"){id,eventManagerId,name,description,location,start,end,totalCapacity,grantedCapacity,usedCapacity,capacityLocationSummery{capacityLocationPath,name,capacity,issued,reserved,granted,available,used,start,end,open,close},hasTimeslots,capacityString} }`;
            const result = await backoffice.sendQuery(query);
            result.data.event.capacity = result.data.event && result.data.event.capacityString ? JSON.parse(result.data.event.capacityString) : null;
            if (result.error) {
                commit(mutationTypes.ERROR, result.error);
            } else {
                commit(mutationTypes.RECEIVED_EVENT, result.data.event);
            }
            commit(mutationTypes.END_JOB, jobTypes.GET_EVENT);
        }
    },

    /**
     * check for event updates without notifying UI
     */
    async checkEventUpdates({ commit, dispatch }, eventId) {
        if(eventId) {
            const query = `query { event(id: "${eventId}"){id,eventManagerId,name,description,location,start,end,totalCapacity,grantedCapacity,usedCapacity,capacityLocationSummery{capacityLocationPath,name,capacity,issued,reserved,granted,available,used,start,end,open,close},hasTimeslots,capacityString} }`;
            const result = await backoffice.sendQuery(query);
            result.data.event.capacity = result.data.event && result.data.event.capacityString ? JSON.parse(result.data.event.capacityString) : null;
            if (!result.error) {
                commit(mutationTypes.RECEIVED_EVENT, result.data.event);
            }
        }
    },

    async setEventId({ commit, dispatch }, eventId) {
        dispatch('clearEvent');
        commit(mutationTypes.SET_EVENT_ID, eventId);
    },

    clearEvent({ commit }) {
        commit(mutationTypes.CLEAR_EVENT);
    },

    async useAccess({ commit }, {token, eventId, eventManagerId, capacityLocationPath}) {
        if(token) {
            commit(mutationTypes.START_JOB, jobTypes.USE_ACCESS);
            commit(mutationTypes.ACCESS_REQUESTED);
            const result = await backoffice.sendCommand('UseAccess', {aggregateId: eventManagerId, eventId, token, requestedLocationPath: capacityLocationPath}, 1);
            if (result.error) {
                commit(mutationTypes.ACCESS_DENIED, result.error);
            } else {
                commit(mutationTypes.ACCESS_GRANTED, 'Ok');
            }
            commit(mutationTypes.END_JOB, jobTypes.USE_ACCESS);
            setTimeout(() => {
                commit(mutationTypes.CLEAR_ACCESS);
            }, 1000);


        }
    },

};
