import { WebClient } from "ticketengine-sdk";
import router from "@/router";

export default class BackendService {

    constructor() {
        this.client = new WebClient({
            authUrl: config.VUE_APP_AUTH_URL || process.env.VUE_APP_AUTH_URL,
            adminApiUrl: config.VUE_APP_ADMIN_API_URL || process.env.VUE_APP_ADMIN_API_URL,
            graphApiUrl: config.VUE_APP_GRAPH_API_URL || process.env.VUE_APP_GRAPH_API_URL
        });
    }

    async getAuthToken({username, password}) {
        try {
            const refreshToken = this.client.getRefreshToken();
            const clientId = 'scanner';
            const clientSecret = '';
            const scope = 'event:read access:use';
            if(refreshToken && refreshToken !== '') {
                return await this.client.getAuthToken({
                    grantType: 'refresh_token',
                    clientId: clientId,
                    clientSecret: clientSecret,
                    scope: scope,
                    refreshToken: refreshToken
                });
            }
            if(username && password) {
                return await this.client.getAuthToken({
                    grantType: 'password',
                    clientId: clientId,
                    clientSecret: clientSecret,
                    scope: scope,
                    username: username,
                    password: password
                });
            }
            router.push({ name: "login" });
        } catch (e) {
            const message = (e.response && e.response.data && e.response.data.data && e.response.data.data.message) ? e.response.data.data.message : (e.response && e.response.data && e.response.data.message) ? e.response.data.message : (e.message) ? e.message : 'Oops something went wrong...' ;
            return { error: message };
        }
    }

    async sendQuery(query) {
        const self = this;
        try {
            if(this.client.isTokenExpired()) {
                await this.getAuthToken({});
            }
            return await this.client.query(query);
        } catch (e) {
            // if(e.response && e.response.status && e.response.status === 401) {}
            if(e.response && e.response.status && (e.response.status === 401 || e.response.status === 403) && tryCount < 2) {
                await self.getAuthToken({}); // get new token
                return self.sendCommand(type, data, tryCount+1); // retry command with new token
            }
            const message = (e.response && e.response.data && e.response.data.data && e.response.data.data.message) ? e.response.data.data.message : (e.response && e.response.data && e.response.data.message) ? e.response.data.message : (e.message) ? e.message : 'Oops something went wrong...' ;
            return { error: message };
        }
    }

    /**
     * Send a command.
     * Eg. The command RenameEvent will have to call the function renameEvent in the access context of the client. This will result
     * in a call to the function: this.client.access.renameEvent()
     * @param type
     * @param data
     * @param tryCount
     * @returns {Promise<*>}
     */
    async sendCommand(type, data, tryCount = 1) {
        const self = this;
        const commandFunction = type.charAt(0).toLowerCase() + type.slice(1); // name of the command function to look for in the context
        const context = this._findCommandContext(commandFunction); // get the command context
        // return this.client[context][commandFunction](data); // send command
        try {
            if(this.client.isTokenExpired()) {
                await this.getAuthToken({});
            }
            return await this.client[context][commandFunction](data); // send command
        } catch (e) {
            // if unauthorized, get new token and try again
            if(e.response && e.response.status && e.response.status === 401 && tryCount < 2) {
                await self.getAuthToken({}); // get new token
                return self.sendCommand(type, data, tryCount+1); // retry command with new token
            }
            const message = (e.response && e.response.data && e.response.data.data && e.response.data.data.message) ? e.response.data.data.message : (e.response && e.response.data && e.response.data.message) ? e.response.data.message : (e.message) ? e.message : 'Oops something went wrong...' ;
            return { error: message, code: e.response.status };
        }
    }

    /**
     * Send all commands received in batch array
     * @param batch                     [{type: '', data: {}}]
     * @returns {Promise<any[]>}
     */
    async sendCommandBatch(batch) {
        // execute all commands
        const results = await Promise.all(
            batch.map(command => {
                return this.sendCommand(command.type, command.data);
            })
        );

        // check if there are commands that return an error
        let e = null;
        results.forEach(result => {
            if (result.error) {
                e = result.error;
            }
        });

        // return
        if (e) {
            return { error: e };
        }
        return results;
    }

    /**
     * Send command. Retry policy consist of array of amounts of milliseconds that should be waited before the next try.
     * Every entry in the array is a new try.
     * @param type                  command name
     * @param data                  command data
     * @param retryPolicy           array of wait time in milliseconds. Every entry is a try in the retry policy.
     *                              Process wil wait defined amount of milliseconds before the next try
     * @returns {Promise<*>}
     */
    async sendCommandWithRetryPolicy(type, data, retryPolicy = [1000, 1000, 1000]) {
        const self = this;
        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        };

        const result = await self.sendCommand(type, data);
        if (result.error && retryPolicy.length > 0) {
            await sleep(retryPolicy.shift()); // wait x milliseconds
            return await self.sendCommandWithRetryPolicy(type, data, retryPolicy); // retry
        }
        return result;
    }


    _findCommandContext(commandFunction) {
        const commandContexts = [
            "access",
            "order",
            "email",
            "payment",
            "salesChannel",
            "customer",
            "user",
            "tag"
        ]; // list of available command contexts
        for (let i = 0; i < commandContexts.length; i++) {
            if (
                Object.keys(this.client[commandContexts[i]]).indexOf(
                    commandFunction
                ) !== -1
            ) {
                return commandContexts[i];
            }
        }
    }
}


