export const ERROR = "ERROR";
export const FLUSH_ERROR = "FLUSH_ERROR";
export const START_JOB = "START_JOB";
export const END_JOB = "END_JOB";

export const SET_EVENT_ID = "SET_EVENT_ID";
export const RECEIVED_EVENT = "RECEIVED_EVENT";
export const CLEAR_EVENT = "CLEAR_EVENT";

export const ACCESS_REQUESTED = "ACCESS_REQUESTED";
export const ACCESS_GRANTED = "ACCESS_GRANTED";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const CLEAR_ACCESS = "CLEAR_ACCESS";

