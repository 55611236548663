import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: {message: null},
    jobs: [],
    event: null,
    access: null,
    previousAccess: null,
    // access: {status: 'requested', message: 'some message ...'},
    // access: {status: 'granted', message: 'some message ...'},
    // access: {status: 'denied', message: 'some message ...'},
  },
  mutations,
  actions,
  getters,
  modules: {
  }
})
