<template>
  <div id="app">

<!--      <router-link to="/event">Event</router-link>-->

      <te-header></te-header>

      <div class="container">
          <router-view/>
      </div>


  </div>
</template>

<script>
    import Header from '@/components/Header';

    export default {
        name: "Header",

        components: {
            'te-header': Header,
        },
    }
</script>

<style lang="scss">

    #app {
        /*min-height: 100vh;*/
        /*height: 100%;*/
    }

    .container {
        padding: 0;
        margin: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
    }


</style>
